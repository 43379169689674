@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap);
@import url(https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css);
/* Button */
.btn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: .5rem .8rem;
  margin: .8rem 2px 1rem;
  color: var(--main-text-light);
  background-color: var(--accent-color);
  transition: var(--transition-background);
  font-size: 1rem;
  font-weight: 500;
  font-family: var(--font-ss);
  line-height: 1;
  border: none;
  border-radius: 50px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.btn:hover {
  color: var(--main-text-light);
  background-color: var(--accent-color-tint);
}
.btn i {
  margin-right: .3rem;
  font-size: 20px;
}
.btn.media_hide i {
  margin-right: 0;
  display: none;
}
@media (max-width: 420px) {
  .btn span {
    display: none;
  }
  .btn.media_hide i {
    display: block;
  }
  .bx-user-plus, .bx-log-in {
    position: relative;
  }
  .bx-user-plus {
    left: 1px;
  }
  .bx-log-in {
    right: 2px;
  }
}

.btn.hollow {
  background-color: transparent;
  color: var(--accent-color);
  border: 2px solid var(--accent-color);
  transition: border-color .2s;
}
.btn.hollow:hover {
  border-color: var(--accent-color-tint);
}
.btn.hollow:hover span{
  color: var(--accent-color-tint);
}
.themeBtn {
  background-image: linear-gradient(90deg,#1fa2ff 0,#12d8fa 51%,#1fa2ff);
  background-size: 200%;
  color: white;
  border: none;
  transition: all .3s;
}
.themeBtn:hover {
  background-position: 100%;
    transition: all .3s;
}

.btn.main {
  height: 36px !important;
  padding: .3rem .8rem;
  margin: .3rem 0 !important;
}

.btn.secondary {
  border: 2px solid var(--secondary-bg-tint);
  background-color: transparent;
  color: var(--actions-color);
}

.secondary_btn {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 12px;
  width: -webkit-max-content;
  width: max-content;
  min-width: 100px;
  height: 36px;
  color: var(--main-text);
  background-color: var(--secondary-bg-tint);
  transition: var(--transition-background);
  font-size: 1rem;
  border-radius: var(--border-radius);
  margin: 8px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.buttons_group {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.buttons_group .btn:not(:first-child) {
  margin-left: .5rem;
}

.btn.send_btn {
  margin: 0 0 0 .5rem;
  border-radius: var(--border-radius);
}
.btn.send_btn i {
  margin: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: '';
  left: -8px;
  z-index: -1;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
}

.react-datepicker-wrapper {
  width: 100%;
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-size: .8rem;
  background-color: var(--secondary-bg-tint);
  color: var(--main-text);
  border-radius: var(--border-radius);
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  background-color: var(--secondary-bg);
  padding-top: .5rem;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.react-datepicker__header--time {
  padding-bottom: .5rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--actions-color);
  font-weight: 500;
  font-size: 1rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  font-family: 'boxicons';
  font-size: 28px;
  background: none;
  color: var(--accent-color);
  position: absolute;
  top: 6px;
  border: none;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  overflow: hidden;
  transition: var(--transition-background);
  cursor: pointer;
  z-index: 1;
}

.react-datepicker__navigation--previous {
  left: 15px;
}

.react-datepicker__navigation--previous::before {
  content: '\e9af';
}

.react-datepicker__navigation--previous:hover {
  color: var(--accent-color-tint);
  background-color: var(--secondary-bg-tint);
}

.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
}

.react-datepicker__navigation--next::before {
  content: '\e9b2';
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}

.react-datepicker__navigation--next:hover {
  color: var(--accent-color-tint);
  background-color: var(--secondary-bg-tint);
}

.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
  color: var(--actions-color);
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  color: var(--actions-color);
}

.react-datepicker__navigation--years-previous:hover {
  color: var(--actions-color);
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  color: var(--actions-color);
}

.react-datepicker__navigation--years-upcoming:hover {
  color: var(--actions-color);
}

.react-datepicker__year {
  margin: .4rem;
  text-align: center;
}

.react-datepicker__year-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  max-width: 180px;
}

.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  width: 80px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background-color: var(--secondary-bg-tint-focus);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100%;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  height: 100% !important;
  max-height: 235px;
  overflow-y: auto;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: .3rem;
  white-space: nowrap;
  transition: var(--transition-background);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: var(--secondary-bg);
  cursor: pointer;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: var(--accent-color);
  color: var(--secondary-text-light);
  font-weight: 500;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: var(--accent-color);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: var(--actions-color);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  background-color: transparent;
  cursor: default;
}

.react-datepicker__week-number {
  color: var(--main-text);
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: .166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: var(--secondary-bg-tint);
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
  display: -webkit-flex;
  display: flex;
  margin: 0 .3rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--main-text);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 28px;
  height: 28px;
  text-align: center;
  margin: .2rem;
}

.react-datepicker__day-name {
  color: var(--actions-color);
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  background-color: var(--secondary-bg-tint);
  color: var(--main-text);
}

.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: var(--secondary-bg-tint);
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: var(--action-color);
  pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  background-color: transparent;
  cursor: default;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  border-radius: 50px;
  transition: var(--transition-background);
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: var(--secondary-bg);
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  background-color: var(--secondary-bg-tint-focus);
  font-weight: 500;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  background-color: var(--secondary-bg-tint);
  color: var(--main-text);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--accent-color);
  color: var(--secondary-text-light);
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: var(--accent-color);
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: var(--accent-color);
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range {
  background-color: rgba(33, 107, 165, .5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range,
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range,
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range {
  background-color: var(--secondary-bg-tint);
  color: var(--main-text);
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: var(--actions-color);
  cursor: default;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: .45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: var(--secondary-bg-tint);
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  text-align: center;
  z-index: 1;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.react-datepicker__close-icon::after {
  background-color: transparent;
  color: var(--main-text);
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: '\00d7';
  cursor: pointer;
}

.react-datepicker__today-button {
  background-color: var(--secondary-bg-tint);
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
  cursor: pointer;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .8);
  left: 0;
  top: 0;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled,
.react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled,
.react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  cursor: default;
}

/* Dropdown Menu */
.head_dropdown {
  position: absolute;
  top: 48px;
  right: -2px;
  width: 250px;
  max-height: calc(100vh - 100px) !important;
  font-size: .95rem;
  background-color: var(--secondary-bg);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 8px -3px rgb(0 0 0 / 20%);
  padding: .4rem;
  overflow: hidden;
  transition-duration: .2s;
  transition-property: height, background-color;
  pointer-events: initial !important;
  cursor: default;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 8;
}

.menu {
  width: 100%;
}
.drop-header {
  margin-bottom: 10px;
}
.menu-item {
  height: 42px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: var(--border-radius-inner);
  transition: var(--transition-background);
  padding: .3rem;
  cursor: pointer;
}
.menu-item .icon-button i {
  font-size: 20px;
}
.icon-button i.bx-left-arrow-alt {
  font-size: 24px;
}
.menu-item:hover {
  background-color: var(--secondary-bg-tint-focus);
}
.menu-item-title {
  margin-left: .5rem;
}
.icon-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: .1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.icon-right {
  margin-left: auto;
  margin-right: .5rem;
  width: 24px;
  height: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.icon-right i {
  font-size: 20px !important;
}

/* CSSTransition  */
.menu-primary-enter {
  position: absolute;
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
}
.menu-primary-enter-active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  transition: all .3s;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
  transition: all .3s;
}

.menu-secondary-enter {
  -webkit-transform: translateX(110%);
          transform: translateX(110%);
}
.menu-secondary-enter-active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  transition: all .3s;
}
.menu-secondary-exit-active {
  -webkit-transform: translateX(110%);
          transform: translateX(110%);
  transition: all .3s;
}

/* Markdown */
.markdown {
  font-weight: 400;
}
.markdown::after {
  content: '';
  clear: both;
  visibility: hidden;
  display: block;
  height: 0;
}
.markdown p {
  word-break: break-word;
}
.markdown em {
  font-style: italic;
}
.markdown strong {
  font-weight: 700;
}
.markdown ol, .markdown ul {
  margin-left: 1.4rem;
  margin-top: .3rem;
  margin-bottom: .3rem;
}
.markdown ul {
  list-style: disc outside none;
  list-style: initial;
}
.markdown ul > li > ul {
  list-style: revert;
}
.markdown code, .markdown pre {
  font-family: monospace;
  background-color: var(--dark-bg) !important;
  border-radius: var(--border-radius-inner);
  padding-right: .8rem;
}
.markdown pre {
  margin: .5rem 0;
  padding: .2rem .3rem !important;
  display: -webkit-flex;
  display: flex;
  overflow: auto;
  white-space: nowrap;
}
.markdown .linenumber {
  min-width: 36px !important;
}
.markdown blockquote {
  position: relative;
  display: -webkit-flex;
  display: flex;
  color: var(--main-success-color);
  margin-top: .5rem;
  margin-bottom: .5rem;
}
.markdown blockquote::before {
  content: '>';
  position: relative;
  top: -1px;
  margin-right: .2rem;
}
.markdown h1 {
  font-size: 2rem;
  margin: 1rem 0;
}
.markdown h2 {
  font-size: 1.5rem;
  margin: .8rem 0;
}
.markdown h3 {
  font-size: 1.25rem;
  margin: .5rem 0;
}
.markdown h4 {
  font-size: 1rem;
  margin: .4rem 0;
}
.markdown h5 {
  font-size: .8rem;
  margin: .3rem 0;
}
.markdown h6 {
  font-size: .7rem;
  margin: .2rem 0;
}
.markdown img {
  display: -webkit-flex;
  display: flex;
  border-radius: var(--border-radius-inner);
  width: 100%;
  max-width: 280px;
  float: left;
  margin: .3rem 1.2rem .3rem 0;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.markdown hr {
  border: 1px solid var(--form-border-color);
  transition: border-color .2s;
  margin: .7rem 0;
}
.markdown .spoiler {
  background-color: var(--main-bg);
  transition: var(--transition-background);
  border-radius: var(--border-radius-inner);
}
.markdown .spoiler_text {
  visibility: hidden;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.markdown .spoiler:hover {
  background-color: transparent;
}
.markdown .spoiler:hover .spoiler_text {
  visibility: visible;
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
}

/* Image lightbox */
@-webkit-keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ril__outer {
  background-color: rgba(0, 0, 0, .8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
  z-index: 1000;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 35px;
  padding: 30px 25px;
  margin: auto;
  opacity: .7;
  cursor: pointer;
}
.ril__navButtons:hover {
  opacity: 1;
}
.ril__navButtons:active {
  opacity: .7;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, .2);
  position: absolute;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: var(--main-text-light);
}

.ril__toolbar {
  top: 0;
  height: 50px;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  margin: 0 !important;
  padding-left: 1rem;
  padding-right: 0;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  margin: 0 !important;
  padding-left: 0;
  padding-right: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
}

.ril__toolbarItem {
  display: -webkit-flex;
  display: flex;
  padding: 0;
  color: var(--main-text-light);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinTitle {
  margin-right: .5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  opacity: .7;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ril__builtinButton {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  opacity: .7;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ril__builtinButton:hover {
  opacity: 1;
}
a.ril__builtinButton {
  color: var(--main-text-light);
  font-size: 20px;
}

.ril__builtinButtonDisabled {
  opacity: .5;
  cursor: default;
}
.ril__builtinButtonDisabled:hover {
  opacity: .5;
}

.ril__zoomOutButton, .ril__zoomInButton, .ril__closeButton {
  font-family: 'boxicons';
  background: none !important;
  color: var(--main-text-light);
  font-size: 22px;
}
.ril__closeButton {
  font-size: 30px;
}
.ril__zoomOutButton::before {
  content: '\ebed';
}
.ril__zoomInButton:before {
  content: '\ebec';
}
.ril__closeButton::before {
  content: '\ebe9';
}

.ril__navButtonPrev, .ril__navButtonNext {
  font-family: 'boxicons';
  background-color: rgb(0 0 0 / 20%);
  border-radius: var(--border-radius);
  font-size: 42px;
  color: var(--main-text-light);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.ril__navButtonPrev {
  left: 0;
}
.ril__navButtonPrev::before {
  content: '\e9af';
}
.ril__navButtonNext {
  right: 0;
}
.ril__navButtonNext::before {
  content: '\e9b2';
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  background-size: cover;
}

.ril__outerAnimating {
  -webkit-animation-name: closeWindow;
          animation-name: closeWindow;
}

@-webkit-keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  width: 50px;
  height: 50px;
  position: relative;
  border: 5px solid transparent;
  border-top: 5px solid var(--main-info-color);
  border-radius: 50%;
  -webkit-animation: spin 1.5s linear infinite;
          animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.ril__loadingCirclePoint {
  display: none;
}
.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ril__loadingContainer__icon {
  color: var(--main-text-light);
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}

.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: var(--main-text-light);
}
.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

/* Notifications dropdown */
.with_notifications {
  width: 380px;
  max-width: 600px;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
}

@media (max-width: 400px) {
  .with_notifications {
    right: -68px;
    width: 100vw;
    max-width: none !important;
    max-width: initial !important;
    max-height: calc(100vh - 66px) !important;
  }
}

/* Header */
.app_head {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  max-width: 1200px;
  background-color: var(--main-bg);
  transition: var(--transition-background);
  height: 72px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 8;
}
.head_inner {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 1rem;
  position: relative;
}
.head_left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.open_nav {
  display: none;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 36px;
  height: 36px;
  margin-right: 1rem;
  cursor: pointer;
}
.open_nav > * {
  pointer-events: none;
}
.app_name img{
  height: 66px;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.app_name span{
  margin-top: 10px;
}
.app_name {
  font-size: 1.5rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;-webkit-align-items: center;align-items: center;
}
.head_act {
  display: -webkit-flex;
  display: flex;
}
.head_act_item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 36px;
  height: 36px;
  margin-left: 1rem;
  border-radius: var(--border-radius);
  transition: var(--transition-background);
  cursor: pointer;
}
.head_act_item:hover {
  background-color: var(--secondary-bg-tint-focus)
}
.head_search.open > i {
  pointer-events: none;
}
.head_act_item i, .head_search i, .open_nav i {
  font-size: 22px;
}

.notifications {
  position: relative;
  color: var(--main-text);
}
.bxs-bell.bx-tada {
  color: var(--accent-color);
}

.head_profile {
  width: 32px;
  height: 32px;
  background-color: var(--secondary-bg-tint);
  transition: var(--transition-background);
  background-size: cover;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: var(--actions-color);
  position: relative;
  pointer-events: initial !important;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.head_profile::before {
  content: '';
  border-radius: 50%;
  border: 1px solid rgb(0 0 0 / 5%);
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 0;
  left: 0;
}
.head_search {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 36px;
  margin-left: .5rem;
}
.head_search_field {
  width: 220px;
  height: 36px;
  background-color: var(--secondary-bg-tint);
  color: var(--main-text);
  border: 2px solid transparent;
  border-radius: 50px;
  padding: .6rem 1rem;
  transition-duration: .2s;
  transition-property: border-color, background-color;
  z-index: 1;
}
.head_search_field:focus {
  border-color: var(--form-border-color-active);
}
.head_search_ic {
  display: none !important;
}
@media (max-width: 600px) {
  .head_search {
    -webkit-justify-content: center;
            justify-content: center;
    width: 36px;
    border-radius: var(--border-radius);
    transition: var(--transition-background);
    cursor: pointer;
  }
  .head_search:hover {
    background-color: var(--secondary-bg-tint-focus)
  }
  .head_search_form {
    display: none;
  }
  .head_search.open .head_search_form {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    right: 0;
    width: calc(100% - 48px);
  }
  .head_search.open .head_search_field {
    width: 100%;
  }
  .head_search_ic {
    display: block !important;
  }
}
.head_auth {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: .5rem;
}
.head_auth .btn {
  margin: 0;
  height: 36px;
}
.showLg {
  display: inline-block;
}
.showSm {
  display: none;
}
@media (max-width: 768px) {
  .showLg {
    display: none;
  }
  .showSm {
    display: inline-block;
  }
}
/* LeftMenu */
.nav_links {
  margin: .8rem .6rem;
}
.nav_item, .group_title {
  border-radius: var(--border-radius);
  transition: var(--transition-background);
  margin: .4rem;
  font-size: 1.05rem;
}
.nav_item:hover a, .nav_item.active a{
  color: var(--secondary-text-active);
  background-image: linear-gradient(0deg,#12d8fa,#1fa2ff);
}
.nav_item:hover, .nav_item.active {
  
}
.nav_item a.active {
  background-image: linear-gradient(0deg,#12d8fa,#1fa2ff);
  color: #fff;
}
.nav_item.active {
  pointer-events: none;
}
.nav_item a, .group_title span {
  display: block;
  padding: .6rem .6rem .6rem 1rem;
  font-weight: 500;
}
.nav_item a {
  color: var(--secondary-text);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-radius: var(--border-radius);
}
.nav_item > a > .nav_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.group_title span {
  color: var(--actions-color);
  font-weight: 600;
}
.nav_counter {
  font-size: .8rem;
  font-weight: 400;
  background-color: var(--accent-color);
  color: var(--main-text-light);
  border-radius: var(--border-radius);
  padding: 1px 8px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.nav_counter.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  padding: 0;
}

.with_notif {
  position: relative;
}
.with_notif::after {
  content: '';
  background-color: var(--accent-color);
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -3px;
  width: 10px;
  height: 10px;
}

/* Layout */
.container {
  position: relative;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 1200px;
  min-width: 300px;
  margin: 0 auto;
}
.left_bar {
  width: 252px;
  position: -webkit-sticky;
  position: sticky;
  top: 4.5rem;
  height: calc(100vh - 72px);
  overflow-y: auto;
  overflow-x: hidden;
}
.content {
  width: 63%;
}
.rigth_bar {
  width: 15%;
  top: 5.2rem;
  position: -webkit-sticky;
  position: sticky;
  z-index: 7;
  margin: .7rem .6rem;
  max-height: calc(100vh - 100px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 780px) {
  .open_nav {
    display: -webkit-flex;
    display: flex;
  }
  .left_bar {
    background-color: var(--main-bg);
    position: fixed;
    top: 0;
    left: 0;
    width: 240px;
    height: 100%;
    -webkit-transform: translate(-260px, 0);
            transform: translate(-260px, 0);
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    z-index: 9;
  }
  .left_bar.open {
    display: -webkit-flex;
    display: flex;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .content, .main_nav {
    width: 100% !important;
  }
  .rigth_bar {
    margin: 0 !important;
    position: fixed !important;
    top: auto !important;
    top: initial !important;
    bottom: 1rem;
    right: 1rem;
  }
}


.fab {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-image: linear-gradient(90deg,#1fa2ff 0,#12d8fa 51%,#1fa2ff);
  background-size: 200%;
  color: white;
  border: none;
  height: 48px;
  border-radius: 50px;
  transition: all .3s;
  box-shadow: 0 2px 8px -3px rgb(0 0 0 / 80%);
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.fab:hover {
  transition: all .3s;
  background-position: 100%;
}
.fab span {
  margin-right: .8rem;
}
.fab i {
  font-size: 20px;
}
.fab.secondary {
  background-color: var(--secondary-bg-tint);
  box-shadow: none;
  box-shadow: initial;
  margin-top: auto;
}

@media (max-width: 1050px) {
  .content {
    width: 58%;
  }
  .rigth_bar {
    top: 4.9rem;
    margin: .4rem .6rem;
    padding: 0;
    width: auto;
    width: initial;
  }
  .fab {
    width: 56px;
    height: 56px;
  }
  .fab span {
    display: none;
  }
}


.cover {
  content: '';
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0 0 0 / 60%);
  -webkit-animation: growing .3s;
          animation: growing .3s;
  z-index: 8;
}
.cover.open {
  display: block;
}
@-webkit-keyframes growing {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: rgb(0 0 0 / 60%);
  }
}
@keyframes growing {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: rgb(0 0 0 / 60%);
  }
}

/* Footer */
.general_footer {
  margin: 5rem 1rem 3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.copyright {
  font-weight: 500;
}
.general_footer ul {
  display: -webkit-flex;
  display: flex;
  margin: 0 0 0 auto;
}
@media (max-width: 520px) {
  .general_footer {
    display: block;
  }
  .general_footer ul {
    margin-left: 0;
    margin-left: initial;
    margin-top: .6rem;
  }
}
@media (min-width: 750px) and (max-width: 900px) {
  .general_footer {
    display: block;
  }
  .general_footer ul {
    margin-left: 0;
    margin-left: initial;
    margin-top: .6rem;
  }
}
.footer_link {
  margin: 0 .5rem;
  white-space: nowrap;
}
.footer_link:first-child {
  margin-left: 0;
}
.footer_link a {
  font-size: .9rem;
  color: var(--secondary-text);
}
.footer_link a:hover {
  -webkit-text-decoration: underline var(--secondary-text);
          text-decoration: underline var(--secondary-text);
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  text-underline-position: under;
}

/* Section */
.main_section {
  margin: 1.1rem 1rem 0;
}
.main_section:not(:first-child) {
  margin: 2rem 1rem 0;
}
.main_section:last-child {
  margin-bottom: 6rem;
}
.section_header {
  margin-bottom: 1.1rem;
}
.section_header > h2 {
  color: var(--secondary-text);
  font-size: 1.3rem;
  font-weight: 700;
  margin: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.with_link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.with_link > .more_link {
  display: -webkit-flex;
  display: flex;
  margin-left: auto;
}
.more_link a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: var(--accent-color);
  padding: .2rem 0 .2rem 1rem;
}
.more_link i {
  font-size: 22px;
  margin-left: 2px;
}
.with_link > .more_link:hover i {
  position: relative;
  right: -4px;
}

/* PopularBoards */
.boards_slide {
  position: relative;
}

.boards_slide_nav {
  position: absolute;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.slide_nav_btn {
  border-radius: 50%;
  color: var(--main-text-light);
  background-color: rgb(0 0 0 / 30%);
  transition: var(--transition-background);
  width: 28px;
  height: 28px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 24px;
  cursor: pointer;
}
.slide_nav_btn:hover {
  background-color: rgb(0 0 0 / 50%);
}
.boards_slide_nav.prev {
  left: -12px;
}
.boards_slide_nav.next {
  right: -12px;
}
.boards_slide_nav.hide {
  display: none;
}

.boards_slide_list, .slides_list {
  display: grid;
  grid-template-columns: repeat(6, minmax(112px, 1fr));
  grid-gap: 8px;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}
.slides_list {
  grid-template-columns: repeat(5, minmax(112px, 1fr));
}
@media (max-width: 746px) {
  .boards_slide_list::-webkit-scrollbar,
  .slides_list::-webkit-scrollbar,
  .sort_nav::-webkit-scrollbar,
  .breadcrumb_body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.boards_slide_item, .stats_item {
  border-radius: var(--border-radius);
  scroll-snap-align: center;
  scroll-snap-stop: always;
}
.boards_slide_item {
  width: 122px;
  height: 122px;
  background-color: var(--accent-color);
}
.stats_item {
  width: 100% !important;
  height: 112px !important;
  background-color: var(--secondary-bg);
  transition: var(--transition-background);
}

.slide_item_text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: var(--main-text-light);
  width: 100%;
  height: 100%;
  padding: 10px;
}
.stats_item .slide_item_text {
  color: var(--main-text) !important;
}

.slide_title {
  font-size: 1.1rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.slide_content {
  font-size: .9rem;
  color: var(--secondary-text-light);
}
.stats_item .slide_content {
  color: var(--secondary-text);
}

/* Breadcrumbs */
.breadcrumbs {
  margin: 1.4rem 0 1rem 0;
}
.breadcrumbs > * {
  font-size: .9rem;
  font-weight: 500;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.breadcrumb_body {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: var(--actions-color);
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}
.breadcrumbs_link {
  color: var(--accent-color);
}
.breadcrumbs_item {
  display: -webkit-flex;
  display: flex;
  scroll-snap-align: end;
  scroll-snap-stop: always;
}
.breadcrumbs_item:first-child::before {
  content: '';
  margin: 0
}
.breadcrumbs_item::before {
  content: '/';
  margin: 0 .4rem;
  font-size: .8rem;
  font-weight: 400;
  color: var(--actions-color);
  position: relative;
  top: 1px;
  opacity: .8;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* SortNav */
.sort_nav {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.sort_item {
  background-color: var(--secondary-bg-tint);
  border-radius: var(--border-radius);
  font-size: .95rem;
  padding: .1rem .6rem;
  margin-right: .5rem;
  transition: var(--transition-background);
  scroll-snap-align: start;
  scroll-snap-stop: always;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.sort_item.active {
  color: var(--accent-color);
}

/* Profile */
.profile_head {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: initial;
          align-items: initial;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 1.5rem 0;
}
.profile_picture {
  width: 100px;
  height: 100px;
  color: var(--actions-color);
  background-color: var(--secondary-bg-tint);
  position: relative;
  background-size: cover;
  border-radius: 50%;
  margin-top: .5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 1.8rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.profile_picture::before {
  content: '';
  border-radius: 50%;
  border: 2px solid rgb(0 0 0 / 5%);
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  position: absolute;
  top: 0;
  left: 0;
}
.profile_head_right {
  margin: .5rem .5rem 1rem 1.5rem;
  overflow: hidden;
}
.profile_username {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: .5rem 0;
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile_online {
  color: var(--actions-color);
}
.profile_head_actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.profile_head_actions .btn:not(:first-child) {
  margin-left: .5rem;
}
@media (max-width: 500px) {
  .profile_head {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .profile_head_right {
    margin: 1rem .5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .profile_username {
    max-width: 280px;
  }
}

.profile_stats_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow-x: auto;
  white-space: nowrap;
}
.profile_stats_item {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-radius: var(--border-radius-inner);
  transition: var(--transition-background);
  min-width: 76px;
  height: 72px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.profile_stats_item:hover {
  background-color: var(--secondary-bg-tint-focus);
}
.profile_stats_item:not(:first-child)::before {
  content: '';
  border-left: 1px solid var(--form-border-color);
  transition: border-left .2s;
  height: 100%;
  position: absolute;
  left: 0;
}
.profile_stats_item .secondary_text {
  margin: 0 0 .3rem;
  font-size: 1.1rem;
}

.positive {
  color: var(--main-success-color);
}
.negative {
  color: var(--main-danger-color);
}

/* Boards actions */
.edit_action_menu {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.edit_action_menu .action {
  border-radius: var(--border-radius);
  width: 32px;
  height: 32px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: var(--transition-background);
  cursor: pointer;
}
.edit_action_menu .action:not(:first-child) {
  margin-left: .3rem;
}
.edit_action_menu .action:hover {
  background-color: var(--secondary-bg-tint);
}
.edit_action_menu .action i {
  font-size: 18px;
}

.edit {
  color: var(--main-success-color) !important;
}
.delete {
  color: var(--main-danger-color) !important;
}
.cancel {
  color: var(--main-info-color) !important;
}
.cancel i {
  font-size: 22px !important;
}

.form_inner.edit_form {
  width: 100%;
  max-width: none;
  max-width: initial;
  margin: .25rem !important;
}

/* Admin */
.admin__nav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 8px;
  margin-top: 2rem;
}
.admin__nav_item {
  position: relative;
  background-color: var(--secondary-bg);
  color: var(--main-text);
  border-radius: var(--border-radius);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 142px;
  font-size: .9rem;
  transition: var(--transition-background);
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
.admin__nav_item:hover {
  background-color: var(--secondary-bg-tint-focus);
}
.admin__nav_item i {
  font-size: 36px;
  margin: .8rem;
  color: var(--secondary-text);
}
.admin__nav_item.new {
  background-color: var(--secondary-bg);
}
.admin__nav_item.new::before {
  content: '';
  position: absolute;
  right: -6px;
  top: -6px;
  width: 16px;
  height: 16px;
  background-color: var(--accent-color);
  border-radius: 50%;
}
@media (max-width: 400px) {
  .admin__nav {
    grid-template-columns: auto;
  }
}

/* Messages */
.main_section.with_hested_scroll {
  margin: 0;
}
.messages_wrapper {
  height: calc(100vh - 180px);
}
.messages_wrapper .card_body.row {
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.messages_wrapper .comments_form {
  position: relative;
  background-color: transparent;
  background-color: initial;
  padding: .5rem 0 !important;
  margin: 0 !important;
}
.messages_wrapper .comments_form.fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--main-bg);
  transition: var(--transition-background);
  z-index: 1;
}
.messages_wrapper .form_block {
  border: none;
}
.messages_wrapper .comments_form.fixed textarea.input_area {
  line-height: 1.4;
  min-height: 36px !important;
  max-height: 98px !important;
}
.messages_wrapper .comments_form .card_item {
  margin: 0 1rem;
}
.send_file {
  font-size: 24px !important;
  margin-right: .3rem;
  padding: .3rem !important;
}
.send_file i {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.comments_form .attach_list {
  position: absolute;
  bottom: 48px;
  left: 0;
}

.messages_wrapper .user_head {
  padding: 0;
  margin: 0 1rem .3rem;
}
.dot_loader::before {
  content: '';
  -webkit-animation: dot_anim 1.2s infinite;
          animation: dot_anim 1.2s infinite;
}
@-webkit-keyframes dot_anim {
  20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  80% {
    content: '';
  }
}
@keyframes dot_anim {
  20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  80% {
    content: '';
  }
}

.messages_wrapper .empty-results, .messages_wrapper .content_loader {
  height: 100%;
  margin: 0 auto;
}
.messages_wrapper .more_loader {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.messages_list {
  margin: 0 1rem;
}

.message_item {
  width: 100%;
}
.message_actions {
  display: none;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  top: 0;
  right: -2.5rem;
  height: 100%;
}
.message_item.my .message_actions {
  left: -2.5rem;
  right: auto;
  right: initial;
}
.message_item:hover .message_actions {
  display: -webkit-flex;
  display: flex;
}

.message_action_item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: var(--border-radius);
  transition: var(--transition-background);
  padding: .5rem;
  font-size: 16px;
  cursor: pointer;
}
.message_action_item:hover {
  background-color: var(--secondary-bg-tint-focus);
}

.message_body {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: var(--secondary-bg);
  transition: var(--transition-background);
  border-radius: var(--border-radius);
  border-bottom-left-radius: 0;
  padding: .4rem .5rem .2rem;
  margin: .45rem 0;
  width: -webkit-max-content;
  width: max-content;
  max-width: 300px;
  min-width: 100px;
}
.message_item.my .message_body {
  background-color: var(--secondary-bg-muted);
}
@media (min-width: 420px) {
  .message_body {
    max-width: 380px;
  }
}
.message_item.my .message_body {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: 0;
  margin-left: auto;
}

.message_content {
  font-size: .95rem;
  word-break: break-word;
}
.message_content p {
  display: inline;
  display: initial;
  white-space: pre-wrap;
}
.message_body.markdown img {
  max-width: 100%;
  margin: 0 0 .3rem;
  float: none;
  float: initial;
}

.message_info {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: var(--actions-color);
  font-size: .65rem;
  float: right;
  margin-top: .6rem;
  margin-left: .85rem;
  height: 15px;
}
.message_info i {
  color: var(--accent-color);
  font-size: 16px;
  position: relative;
  top: -1px;
  margin-left: .3rem;
}

.message_time {
  position: absolute;
  right: .5rem;
  top: .6rem;
  color: var(--actions-color);
  font-size: .7rem;
}

.msg_file_grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border-radius: var(--border-radius-inner);
  overflow: hidden;
  margin-bottom: .3rem;
}
.msg_file_grid.group {
  grid-template-columns: repeat(2, 1fr);
}
.group > *:nth-child(2n) {
  justify-self: end;
}
.group > *:nth-child(2n-1) {
  justify-self: center;
}
.group > *:nth-child(2n-1):nth-last-of-type(1) {
  grid-column: span 2;
}
.one .msg_file {
  min-width: 280px;
  height: 280px;
}
.one .simple_file {
  min-width: 120px !important;
  height: 120px !important;
}
.simple_file .attached_info {
  border-radius: var(--border-radius-inner);
  padding: .1rem .3rem;
  max-width: 120px;
}

.msg_file {
  width: 100%;
  min-width: 140px;
  height: 140px;
  position: relative;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: var(--secondary-bg-tint);
  background-size: cover;
  background-position: center;
  transition: var(--transition-background);
  cursor: pointer;
}

.msg_file.video::before {
  content: '';
  width: 42px;
  height: 42px;
  background-color: rgb(0 0 0 / 40%);
  border-radius: 50%;
}
.msg_file.video::after {
  content: '\eb1e';
  color: var(--main-text-light);
  font-size: 26px;
  position: absolute;
  margin-left: 3px;
}

.group .msg_file:not(.video)::after {
  content: '';
  border: 1px solid rgb(0 0 0 / 2%);
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 0;
  left: 0;
}

.group_date_block {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 1;
}
.group_date_title {
  border-radius: var(--border-radius);
  background-color: rgb(0 0 0 / 25%);
  color: var(--main-text-light);
  font-size: .8em;
  padding: 0 .5rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

:root {
  --font-ss: 'Inter', 'Noto Sans JP', sans-serif;
  --border-radius: 12px;
  --border-radius-inner: calc(var(--border-radius) / 2);
  --transition-background: background-color .2s;

  --accent-color: hsl(212.48deg 100% 66.67%);
  --accent-color-tint: #12d8fa;

  --dark-bg: #191919;

  --main-info-color: #b6bfb7;
  --main-success-color: #4CAF50;
  --main-danger-color: #E53935;

  --main-text-light: #ededed;
  --secondary-text-light: #eeeeee;

  --actions-color: #64707d;
  --main-text: #e8e8e8;
  --main-bg: #161616;
  --secondary-text: #d7cadd;
  --secondary-text-active: #fff;
  --secondary-bg: #2d2d2e;
  --secondary-bg-tint: #1d1d1d;
  --secondary-bg-tint-focus: #191919;
  --secondary-bg-muted: #0c0c0e;
  --form-border-color: #161717;
  --form-border-color-active: var(--accent-color);

  --selection-text: #f2f2f2;
  --selection-bg: #563170;
}
.light {
  --main-text: #3e3e3e;
  /* --main-bg: #eef0f1; */
  --main-bg: #e3efff;
  --secondary-text: #202428;
  --secondary-bg: #ffffff;
  --secondary-bg-tint: #e6e6e6;
  --secondary-bg-tint-focus: #e0e0e0;
  --secondary-bg-muted: #e0e0e0;
  --form-border-color: #eeecec;

  --selection-text: #000000;
  --selection-bg: #dabfff;
}

* {
  box-sizing: border-box;
  outline: none;
  padding: 0;
  margin: 0;
}

html {
  font-size: 16px;
}
body {
  background-color: #161616;
  background-color: var(--main-bg);
  color: #e8e8e8;
  color: var(--main-text);
  font-family: 'Inter', 'Noto Sans JP', sans-serif;
  font-family: var(--font-ss);
  font-weight: 400;
  line-height: 1.5;
  min-height: calc(100vh - 72px);
  overflow: auto;
  transition: background-color .2s;
  transition: var(--transition-background);
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}
body.noscroll {
  overflow: hidden;
}
::selection {
  color: #f2f2f2;
  color: var(--selection-text);
  background-color: #563170;
  background-color: var(--selection-bg);
  text-shadow: none;
}
::-webkit-scrollbar {
  width: 14px;
  height: 12px;
}
::-webkit-scrollbar-thumb {
  border-radius: 50px;
  box-shadow: rgb(173 173 184 / 40%) inset 0 0 10px 10px;
  border: 4px solid transparent;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-input-placeholder {
  color: #64707d;
  color: var(--actions-color);
  -webkit-user-select: none;
          user-select: none;
}
input:-webkit-autofill {
  -webkit-background-clip: text;
  box-shadow: 0 0 0 20px #1d1d1d inset !important;
  box-shadow: 0 0 0 20px var(--secondary-bg-tint) inset !important;
  -webkit-text-fill-color: #e8e8e8 !important;
  -webkit-text-fill-color: var(--main-text) !important;
}
.track-vertical {
  width: 6px;
  right: 3px;
  bottom: 3px;
  top: 3px;
  border-radius: 4px;
  z-index: 1;
}
.track-horizontal {
  display: none;
}
@media (max-width: 570px) {
  .track-vertical {
    width: 4px !important;
    right: 2px;
    bottom: 2px;
    top: 2px;
  }
}
.thumb-vertical, .thumb-horizontal {
  border-radius: inherit;
  background-color: rgb(173 173 184 / 40%);
  cursor: pointer;
}

a {
  color: hsl(212.48deg 100% 66.67%);
  color: var(--accent-color);
  text-decoration: none;
  transition: color .1s;
}
a.flex_margin {
  margin: 0 .4rem;
}
img, br {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
ul {
  list-style: none;
}

#root {
  margin-top: 72px;
}
.view {
  margin-bottom: -17px !important;
}

.content_loader {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 5rem;
}
.content > .content_loader, .main_section > .content_loader {
  height: 40vh;
}
.more_loader {
  height: 36px !important;
  margin-top: 1.5rem;
}

/* Errorer */
.empty-results {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
  margin-top: 40px;
  max-width: 250px;
  min-height: 110px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.empty_words {
  max-width: 250px;
  text-align: center;
  position: relative;
  font-size: 15px;
  color: #d7cadd;
  color: var(--secondary-text);
}
.empty_top {
  margin-top: 8px;
  word-break: break-word;
}

/* Card */
.thread_pin, .thread_lock {
  margin-right: .4rem;
  color: #d7cadd;
  color: var(--secondary-text);
  font-size: 1rem;
}

.cards_inner {
  padding: 0 1.25rem 1.25rem;
}
.card_item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-bottom: .4rem;
}
.card_item.center {
  -webkit-justify-content: center;
          justify-content: center;
}
.card_body {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  min-width: 0;
}
.card_body.row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.row .form_block {
  width: 100%;
}

.card_outside_title {
  margin: .3rem .2rem;
  font-weight: 500;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.card_outside_title.with_hint {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: -webkit-max-content;
  width: max-content;
  cursor: help;
}
.title_hint {
  display: -webkit-flex;
  display: flex;
  margin-left: .5rem;
}
.title_hint i {
  color: #64707d;
  color: var(--actions-color);
  font-size: 18px;
}
.hint_popover {
  display: none;
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  font-size: .8rem;
  background-color: rgb(0 0 0 / .8);
  color: #eeeeee;
  color: var(--secondary-text-light);
  padding: .2rem .4rem;
  border-radius: 12px;
  border-radius: var(--border-radius);
  z-index: 1;
}
.card_outside_title.with_hint:hover .hint_popover {
  display: -webkit-flex;
  display: flex;
  width: -webkit-max-content;
  width: max-content;
  max-width: 180px;
}

.card_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
}
.card_block {
  position: relative;
  background-color: #2d2d2e;
  background-color: var(--secondary-bg);
  color: #e8e8e8;
  color: var(--main-text);
  padding: .25rem;
  border-radius: 12px;
  border-radius: var(--border-radius);
  border: 1px solid #161717;
  border: 1px solid var(--form-border-color);
  transition-duration: .2s;
  transition-property: border-color, background-color;
  overflow-wrap: anywhere;
}
.card_block.noread {
  background-color: #191919;
  background-color: var(--secondary-bg-tint-focus);
}
.with_left {
  display: -webkit-flex;
  display: flex;
}
.left_bar ul {
  background-color: #2d2d2e;
  background-color: var(--secondary-bg);
  color: #e8e8e8;
  color: var(--main-text);
  border: 1px solid #161717;
  border: 1px solid var(--form-border-color);
  border-radius: 12px;
  border-radius: var(--border-radius);
}
.card_left {
  display: -webkit-flex;
  display: flex;
  position: relative;
  background-color: #1d1d1d;
  background-color: var(--secondary-bg-tint);
  background-size: cover;
  background-position: center;
  margin: .4rem;
  width: 74px;
  height: 74px;
  border-radius: calc(12px / 2);
  border-radius: var(--border-radius-inner);
  transition: background-color .2s;
  transition: var(--transition-background);
}
.card_left.empty::before, .msg_file.empty::before {
  content: '';
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTE5LjkzNyw4LjY4Yy0wLjAxMS0wLjAzMi0wLjAyLTAuMDYzLTAuMDMzLTAuMDk0Yy0wLjA0OS0wLjEwNi0wLjExLTAuMjA3LTAuMTk2LTAuMjkzbC02LTYgYy0wLjA4Ni0wLjA4Ni0wLjE4Ny0wLjE0Ny0wLjI5My0wLjE5NmMtMC4wMy0wLjAxNC0wLjA2Mi0wLjAyMi0wLjA5NC0wLjAzM2MtMC4wODQtMC4wMjgtMC4xNy0wLjA0Ni0wLjI1OS0wLjA1MSBDMTMuMDQsMi4wMTEsMTMuMDIxLDIsMTMsMkg2QzQuODk3LDIsNCwyLjg5Nyw0LDR2MTZjMCwxLjEwMywwLjg5NywyLDIsMmgxMmMxLjEwMywwLDItMC44OTcsMi0yVjkgYzAtMC4wMjEtMC4wMTEtMC4wNC0wLjAxMy0wLjA2MkMxOS45ODIsOC44NSwxOS45NjUsOC43NjQsMTkuOTM3LDguNjh6IE0xNi41ODYsOEgxNFY1LjQxNEwxNi41ODYsOHogTTYsMjBWNGg2djUgYzAsMC41NTMsMC40NDcsMSwxLDFoNWwwLjAwMiwxMEg2eiI+PC9wYXRoPjwvc3ZnPg==);
  background-size: cover;
  -webkit-filter: contrast(0);
          filter: contrast(0);
  opacity: .6;
  position: absolute;
  width: 28px;
  height: 28px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
img.card_left, video.card_left {
  width: calc(100% - 38px);
  height: 100%;
  margin-bottom: 1rem;
  cursor: pointer;
}
.card_right {
  width: calc(100% - 88px);
  overflow: hidden;
}
.card_head {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: .2rem .75rem 0;
  margin-top: .3rem;
  font-size: .85rem;
  position: relative;
}
@media (min-width: 400px) {
  .card_head {
    font-size: .95rem;
  }
}
.card_head.column {
  -webkit-flex-direction: column;
          flex-direction: column;
}
.card_head_inner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  line-height: 1.2;
}
.card_head_inner.row {
  -webkit-flex-direction: row;
          flex-direction: row;
}
.card_title {
  font-size: 1.15rem;
  width: calc(100% - 2rem);
  color: #e8e8e8;
  color: var(--main-text);
  transition: color .2s;
}
.card_title i {
  position: relative;
  top: 1px;
  margin-right: .1rem;
}
.card_title.full {
  display: block;
  margin-bottom: .3rem;
}
a.card_title:hover {
  color: hsl(212.48deg 100% 66.67%);
  color: var(--accent-color);
}
.file_card .card_title {
  font-size: 1.05rem;
  -webkit-line-clamp: 1;
}
.card_info {
  margin-top: .3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: -webkit-max-content;
  width: max-content;
}
.head_text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #64707d;
  color: var(--actions-color);
  overflow-wrap: break-word;
  font-size: .85rem;
}
.online {
  background-color: #4CAF50;
  background-color: var(--main-success-color);
  width: 6px;
  height: 6px;
  margin-left: .3rem;
  border-radius: 50%;
}
a.head_text:hover {
  color: #12d8fa;
  color: var(--accent-color-tint);
}
.head_text time {
  white-space: nowrap;
  font-size: .8rem;
}
.bold {
  font-weight: 600;
}
.bullet {
  margin: 0 .5rem;
  color: #64707d;
  color: var(--actions-color);
  font-size: .7rem;
  opacity: .4;
  cursor: default;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.user_status {
  font-size: .6rem;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 12px;
  border-radius: var(--border-radius);
  background-color: hsl(212.48deg 100% 66.67%);
  background-color: var(--accent-color);
  color: #eeeeee;
  color: var(--secondary-text-light);
  padding: .1rem .3rem;
  height: 15px;
  margin-left: .4rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.user_status.adm {
  background-color: #E53935;
  background-color: var(--main-danger-color);
}
.user_status.mod {
  background-color: #4CAF50;
  background-color: var(--main-success-color);
}
.user_status.ic {
  padding: 0 .35rem;
}
.user_status.ic svg {
  fill: currentColor;
  width: 13px;
}
.user_status.ban {
  color: #161616;
  color: var(--main-bg);
  background-color: #e8e8e8;
  background-color: var(--main-text);
  transition: background-color .2s;
  transition: var(--transition-background);
}

.dropdown {
  position: absolute;
  right: .2rem;
  top: .25rem;
}
.dropdown_trigger {
  opacity: .5;
  padding: 0 6px;
}
.dropdown_trigger > * {
  pointer-events: none;
}
.dropdown:hover .dropdown_trigger {
  opacity: 1;
}
.dropdown_content {
  display: none;
  position: absolute;
  right: 0;
  width: -webkit-max-content;
  width: max-content;
  min-width: 128px;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 40%);
  background-color: #1d1d1d;
  background-color: var(--secondary-bg-tint);
  border-radius: 12px;
  border-radius: var(--border-radius);
  overflow: hidden;
  z-index: 1;
}
.dropdown.open .dropdown_content {
  display: -webkit-flex;
  display: flex;
}
.dropdown_item {
  padding: .4rem 1rem;
  color: #d7cadd;
  color: var(--secondary-text);
  transition: background-color .2s;
  transition: var(--transition-background);
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dropdown_item:hover {
  background-color: #191919;
  background-color: var(--secondary-bg-tint-focus);
}

.card_content {
  color: #d7cadd;
  color: var(--secondary-text);
  margin: .5rem .75rem 0;
  font-size: .97rem;
  font-weight: 500;
  line-height: 1.6;
  overflow-wrap: break-word;
}
@media (min-width: 400px) {
  .card_content {
    font-size: 1rem;
  }
}
.text_show_more {
  color: hsl(212.48deg 100% 66.67%);
  color: var(--accent-color);
  font-size: .9rem;
  margin: .75rem;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.secondary_text {
  color: #64707d;
  color: var(--actions-color);
  font-size: .95rem;
  margin-right: .5rem;
}

.attach_list {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}
.card_content.with_attach_list {
  /* display: flex; */
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.with_attach_list .attached_file.image_file.card_left {
  width: 112px;
  height: 112px;
}
.card_content .attach_list {
  margin: .5rem .5rem 0 0;
  overflow: visible;
  overflow: initial;
}
.attach_grid {
  display: grid;
  grid-template-columns: repeat(2, 74px);
  grid-gap: .5rem;
  margin: 1rem 0 .5rem 0;
}
.card_content .attached_file {
  cursor: pointer;
}
.attached_file {
  display: table;
  border-radius: 12px;
  border-radius: var(--border-radius);
  margin: 0 .5rem 0 0;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
.attached_info {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #eeeeee;
  color: var(--secondary-text-light);
  font-size: .7rem;
  border-radius: 12px;
  border-radius: var(--border-radius);
  padding: 0 .3rem;
  margin: .3rem;
  max-width: 64px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.remove_file {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: .2rem;
  right: .2rem;
  border-radius: 50%;
  color: #eeeeee;
  color: var(--secondary-text-light);
  transition: background-color .2s;
  transition: var(--transition-background);
  cursor: pointer;
}
.attached_info, .remove_file {
  background-color: rgb(0 0 0 / .4);
  line-height: normal;
  line-height: initial;
}
.remove_file i {
  font-size: 20px;
}
.remove_file > * {
  pointer-events: none;
}

.card_foot {
  margin: .6rem .2rem .2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.act_btn {
  display: -webkit-flex;
  display: flex;
  background-color: transparent;
  color: #e8e8e8;
  color: var(--main-text);
  border: none;
  border-radius: 12px;
  border-radius: var(--border-radius);
  transition: background-color .2s;
  transition: var(--transition-background);
  cursor: pointer;
}
.act_btn:not(.disable):hover {
  background-color: #1d1d1d;
  background-color: var(--secondary-bg-tint);
}
.act_btn i {
  font-size: 22px;
}
.act_btn.disable {
  cursor: default;
}
.foot_btn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: .4rem;
  padding: .1rem .5rem .1rem;
  font-size: .8rem;
  color: #64707d;
  color: var(--actions-color);
  white-space: nowrap;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.foot_btn i {
  font-size: 17px;
  line-height: 19px;
}
.under_foot {
  margin: .5rem .2rem 0;
  font-size: .72rem;
}
.under_foot i {
  font-size: 15px;
}
.card_count, .hidden, .count_title {
  margin-left: .3rem;
}
.hidden {
  display: none;
}
@media (min-width: 400px) {
  .hidden {
    display: block;
  }
}
.relative {
  position: relative;
}
.liked {
  color: #E53935;
  color: var(--main-danger-color);
}
.pop_list {
  display: none;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  bottom: 22px;
  left: -4px;
  background-color: rgb(0 0 0 / 50%);
  border-radius: 12px;
  border-radius: var(--border-radius);
  padding: .15rem .25rem;
  cursor: default;
  z-index: 1;
}
.relative:hover .pop_list {
  display: -webkit-flex;
  display: flex;
  overflow-x: auto;
  max-width: 170px;
}
.pop_list .head_profile:not(:last-child) {
  margin-right: .2rem;
}
.pop_list .head_profile {
  -webkit-flex: none;
          flex: none;
  cursor: pointer;
}
.pop_list span {
  margin: 0 .5rem 0 .2rem;
  color: #eeeeee;
  color: var(--secondary-text-light);
}

.user_title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.user_title .card_back {
  color: #e8e8e8;
  color: var(--main-text);
  width: 32px;
  height: 32px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 24px;
  margin-right: .5rem;
  cursor: pointer;
}
.user_title .head_profile {
  margin-right: .75rem;
}
.user_head {
  padding: .2rem .5rem;
  margin: 0;
}
.user_info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  overflow: hidden;
  line-height: 1.8;
}
.user_info .head_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.user_info_top {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  line-height: 1.2;
  color: #d7cadd;
  color: var(--secondary-text);
}

/* Modal */
.modal {
  display: none;
  position: fixed;
  top: 0;
  background-color: #161616;
  background-color: var(--main-bg);
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-animation: slide-in .4s;
          animation: slide-in .4s;
  z-index: 9;
}
.modal.open {
  display: -webkit-flex;
  display: flex;
}
.modal_body {
  width: 100%;
}
@media (min-width: 780px) {
  .modal {
    top: 50%;
    left: 50%;
    max-width: 600px;
    height: -webkit-max-content;
    height: max-content;
    max-height: 100%;
    border-radius: 12px;
    border-radius: var(--border-radius);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-animation: slide-in-left-shift .2s;
            animation: slide-in-left-shift .2s;
  }
  .modal_body {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .text_area textarea {
    min-height: 13vh !important;
  }
}
@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateY(110vh);
            transform: translateY(110vh);
  }
  100% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
}
@keyframes slide-in {
  0% {
    -webkit-transform: translateY(110vh);
            transform: translateY(110vh);
  }
  100% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
}
@-webkit-keyframes slide-in-left-shift {
  0% {
    -webkit-transform: translate(-50%, 110vh);
            transform: translate(-50%, 110vh);
  }
  100% {
    -webkit-transform: translate(-50%, calc(0vh - 50%));
            transform: translate(-50%, calc(0vh - 50%));
  }
}
@keyframes slide-in-left-shift {
  0% {
    -webkit-transform: translate(-50%, 110vh);
            transform: translate(-50%, 110vh);
  }
  100% {
    -webkit-transform: translate(-50%, calc(0vh - 50%));
            transform: translate(-50%, calc(0vh - 50%));
  }
}
.modal_head {
  width: 100%;
  padding: 1rem 1rem 0;
}
.modal_head .section_header {
  margin: 0;
}
.modal_head .more_link {
  padding: .4rem;
  border-radius: 12px;
  border-radius: var(--border-radius);
  transition: background-color .2s;
  transition: var(--transition-background);
  cursor: pointer;
}
.modal_head .more_link:hover {
  background-color: #191919;
  background-color: var(--secondary-bg-tint-focus)
}
.modal_head .more_link i {
  font-size: 24px;
  margin: 0;
}
.modal_head .more_link:hover i {
  position: static;
  right: auto;
  right: initial;
}
.modal_head .more_link > * {
  pointer-events: none;
}

.form_inner {
  max-width: 480px;
  margin: 1rem 1rem 0;
}
@media (min-width: 520px) {
  .form_inner {
    width: 100%;
    margin: 1rem auto 0 !important;
  }
}
.sign_form {
  margin: 1rem 0 0;
  padding: 16px;
  border-radius: 12px;
  border-radius: var(--border-radius);
  background-color: #2d2d2e;
  background-color: var(--secondary-bg);
}
.sign_form .center {
  -webkit-justify-content: center;
          justify-content: center;
}
.card_item .content_loader {
  height: 32px;
  margin: .8rem 2px 1rem;
}
.text_reference {
  font-size: .85rem;
}

.form_block {
  position: relative;
  background-color: #2d2d2e;
  background-color: var(--secondary-bg);
  color: #e8e8e8;
  color: var(--main-text);
  border-radius: 12px;
  border-radius: var(--border-radius);
  border: 2px solid #161717;
  border: 2px solid var(--form-border-color);
  transition-duration: .2s;
  transition-property: border-color, background-color;
  overflow-wrap: anywhere;
  overflow: hidden;
}
.form_block:focus-within {
  border-color: hsl(212.48deg 100% 66.67%) !important;
  border-color: var(--form-border-color-active) !important;
}
.form_block.error, .form_block.error:focus-within {
  border-color: #E53935 !important;
  border-color: var(--main-danger-color) !important;
}
.form_error {
  color: #E53935;
  color: var(--main-danger-color);
  font-size: .8rem;
  font-weight: 400;
  margin-left: .5rem;
}

.comments_form {
  margin-bottom: 1.5rem !important;
  width: 100%;
  max-width: none;
  max-width: initial;
  margin: 0;
}
@media (max-width: 650px) {
  .comments_form.float {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-bottom: 0 !important;
    background-color: #2d2d2e;
    background-color: var(--secondary-bg);
    width: 100%;
    padding: .4rem .5rem 0;
    z-index: 1;
  }
}

.input_area {
  height: 36px;
  max-height: inherit !important;
  min-height: inherit !important;
}

.select_area {
  -webkit-appearance: none;
          appearance: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.select_area option {
  background-color: #2d2d2e;
  background-color: var(--secondary-bg);
}
.form_block.select {
  position: relative;
}
.form_block.select::before {
  content: '';
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgCj48cGF0aCBkPSJNMTYuMjkzIDkuMjkzTDEyIDEzLjU4NiA3LjcwNyA5LjI5MyA2LjI5MyAxMC43MDcgMTIgMTYuNDE0IDE3LjcwNyAxMC43MDd6Ij48L3BhdGg+PC9zdmc+');
  background-size: cover;
  -webkit-filter: contrast(0);
          filter: contrast(0);
  position: absolute;
  top: .45rem;
  right: .5rem;
  width: 22px;
  height: 22px;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.text_area {
  width: 100%;
  position: relative;
  
}
.text_area::before {
  content: '';
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMyNCIgaGVpZ2h0PSIzMjQiPjxjaXJjbGUgcj0iMzIiIGN5PSIzMyIgY3g9IjI5MiIvPjxjaXJjbGUgcj0iMzIiIGN5PSIxNjMiIGN4PSIyOTIiLz48Y2lyY2xlIHI9IjMyIiBjeT0iMjkyIiBjeD0iMjkyIi8+PGNpcmNsZSByPSIzMiIgY3k9IjE2MyIgY3g9IjE2MyIvPjxjaXJjbGUgcj0iMzIiIGN5PSIyOTIiIGN4PSIxNjMiLz48Y2lyY2xlIHI9IjMyIiBjeT0iMjkyIiBjeD0iMzMiLz48L3N2Zz4=');
  background-size: cover;
  -webkit-filter: contrast(0);
          filter: contrast(0);
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 8px;
  height: 8px;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.text_area textarea, .input_area {
  display: -webkit-flex;
  display: flex;
  background-color: transparent;
  color: #e8e8e8;
  color: var(--main-text);
  border: none;
  padding: .6em .8rem;
  width: 100%;
  max-height: 48vh;
  min-height: 12vh;
  font-family: var(--font);
  font-size: .85rem;
  line-height: 1.5;
  box-shadow: none;
  transition: none;
  resize: vertical;
}
textarea::-webkit-resizer {
  background-color: transparent;
}
.input_area[type=password],
.input_area[name=password],
.input_area[name=newPassword],
.input_area[name=confirmPassword] {
  padding-right: 2rem;
}
.input_action {
  position: absolute;
  right: 4px;
  top: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 20px;
  padding: .2rem;
  color: hsl(212.48deg 100% 66.67%);
  color: var(--accent-color);
  background-color: #2d2d2e;
  background-color: var(--secondary-bg);
  transition: background-color .2s;
  transition: var(--transition-background);
  border-radius: calc(12px / 2);
  border-radius: var(--border-radius-inner);
  cursor: pointer;
  z-index: 1;
}

.file_area {
  margin: .13rem;
}
.file_area input, .comments_form .miniFileInput {
  display: none;
}
.file_input {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  border-radius: 12px;
  border-radius: var(--border-radius);
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.file_input .secondary_btn {
  height: 2rem;
  margin: .1rem .6rem .1rem .1rem;
  border-radius: calc(12px / 2);
  border-radius: var(--border-radius-inner);
  font-size: .9rem;
  pointer-events: none;
}
.file_input span {
  color: #d7cadd;
  color: var(--secondary-text);
  font-size: .8rem;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.file_input_body {
  height: 74px;
}
.file_input_body.hidden {
  display: none;
}

.form_foot {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  padding: .2rem;
  border-bottom: 1px solid #161717;
  border-bottom: 1px solid var(--form-border-color);
}
.act_group {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.bb_btn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 28px;
  height: 28px;
  font-size: 20px;
  margin-left: 4px;
  color: #64707d;
  color: var(--actions-color);
  border-radius: calc(12px / 1.5);
  border-radius: calc(var(--border-radius) / 1.5);
  transition: background-color .2s;
  transition: var(--transition-background);
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.bb_btn:hover {
  background-color: #191919;
  background-color: var(--secondary-bg-tint-focus);
}
.bb_btn > * {
  pointer-events: none;
}
.bb_btn.md {
  margin-left: auto;
  cursor: help;
}
.bb_btn.md:hover {
  background: none;
}

/* Toast */
.Toastify__toast-container {
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  width: 320px;
  color: #ededed;
  color: var(--main-text-light);
  z-index: 9999;
}
.Toastify__toast-container--top-left {
  top: 1rem;
  left: 1rem;
}
.Toastify__toast-container--top-center {
  top: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1rem;
  right: 1rem;
}
.Toastify__toast-container--bottom-left {
  bottom: 1rem;
  left: 1rem;
}
.Toastify__toast-container--bottom-center {
  bottom: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1rem;
  right: 1rem;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: auto;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: 56px;
  padding: 8px;
  border-radius: 12px;
  border-radius: var(--border-radius);
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  direction: ltr;
  cursor: pointer;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--dark {
  background-color: #191919;
  background-color: var(--dark-bg);
  color: #ededed;
  color: var(--main-text-light);
}
.Toastify__toast--default {
  background-color: #b6bfb7;
  background-color: var(--main-info-color);
  color: #e8e8e8;
  color: var(--main-text);
}
.Toastify__toast--info {
  background-color: #b6bfb7;
  background-color: var(--main-info-color);
}
.Toastify__toast--success {
  background-color: #4CAF50;
  background-color: var(--main-success-color);
}
.Toastify__toast--warning {
  background-color: #b6bfb7;
  background-color: var(--main-info-color);
}
.Toastify__toast--error {
  background-color: #E53935;
  background-color: var(--main-danger-color);
}
.Toastify__toast-body {
  margin: auto 0;
  padding: 6px;
}

.Toastify--animate {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: .7s;
          animation-duration: .7s;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}
.Toastify__close-button {
  color: #ededed;
  color: var(--main-text-light);
  background-color: transparent;
  border: none;
  padding: 0;
  opacity: .7;
  transition: .3s;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  cursor: pointer;
}
.Toastify__close-button--default {
  color: #191919;
  color: var(--dark-bg);
  opacity: .3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@-webkit-keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
}

@keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  opacity: .7;
  background-color: rgba(255, 255, 255, .7);
  -webkit-transform-origin: left;
          transform-origin: left;
  z-index: 9999;
}
.Toastify__progress-bar--animated {
  -webkit-animation: Toastify__trackProgress linear 1 forwards;
          animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: auto;
  left: initial;
  -webkit-transform-origin: right;
          transform-origin: right;
}
.Toastify__progress-bar--default {
  background-color: #191919;
  background-color: var(--dark-bg);
}
.Toastify__progress-bar--dark {
  background-color: #191919;
  background-color: var(--dark-bg);
}
@-webkit-keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
  }
}
@-webkit-keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  -webkit-animation-name: Toastify__bounceInLeft;
          animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  -webkit-animation-name: Toastify__bounceInRight;
          animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  -webkit-animation-name: Toastify__bounceInDown;
          animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  -webkit-animation-name: Toastify__bounceInUp;
          animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  -webkit-animation-name: Toastify__bounceOutLeft;
          animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  -webkit-animation-name: Toastify__bounceOutRight;
          animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  -webkit-animation-name: Toastify__bounceOutUp;
          animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  -webkit-animation-name: Toastify__bounceOutDown;
          animation-name: Toastify__bounceOutDown;
}

@-webkit-keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
  }
  to {
    opacity: 0;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
            transform: scale3d(.3, .3, .3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  -webkit-animation-name: Toastify__zoomIn;
          animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  -webkit-animation-name: Toastify__zoomOut;
          animation-name: Toastify__zoomOut;
}

@-webkit-keyframes Toastify__flipIn {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}

@keyframes Toastify__flipIn {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}
@-webkit-keyframes Toastify__flipOut {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes Toastify__flipOut {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  -webkit-animation-name: Toastify__flipIn;
          animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  -webkit-animation-name: Toastify__flipOut;
          animation-name: Toastify__flipOut;
}

@-webkit-keyframes Toastify__slideInRight {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInRight {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInLeft {
  from {
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInUp {
  from {
    -webkit-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    -webkit-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInDown {
  from {
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 500px, 0);
            transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 500px, 0);
            transform: translate3d(0, 500px, 0);
  }
}
@-webkit-keyframes Toastify__slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -500px, 0);
            transform: translate3d(0, -500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -500px, 0);
            transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  -webkit-animation-name: Toastify__slideInLeft;
          animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  -webkit-animation-name: Toastify__slideInRight;
          animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  -webkit-animation-name: Toastify__slideInDown;
          animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  -webkit-animation-name: Toastify__slideInUp;
          animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  -webkit-animation-name: Toastify__slideOutLeft;
          animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  -webkit-animation-name: Toastify__slideOutRight;
          animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  -webkit-animation-name: Toastify__slideOutUp;
          animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  -webkit-animation-name: Toastify__slideOutDown;
          animation-name: Toastify__slideOutDown;
}

