/* Header */
.app_head {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  background-color: var(--main-bg);
  transition: var(--transition-background);
  height: 72px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 8;
}
.head_inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
  position: relative;
}
.head_left {
  display: flex;
  align-items: center;
}
.open_nav {
  display: none;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-right: 1rem;
  cursor: pointer;
}
.open_nav > * {
  pointer-events: none;
}
.app_name img{
  height: 66px;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.app_name span{
  margin-top: 10px;
}
.app_name {
  font-size: 1.5rem;
  user-select: none;
  color: #000;
  font-weight: bold;
  display: flex;align-items: center;
}
.head_act {
  display: flex;
}
.head_act_item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-left: 1rem;
  border-radius: var(--border-radius);
  transition: var(--transition-background);
  cursor: pointer;
}
.head_act_item:hover {
  background-color: var(--secondary-bg-tint-focus)
}
.head_search.open > i {
  pointer-events: none;
}
.head_act_item i, .head_search i, .open_nav i {
  font-size: 22px;
}

.notifications {
  position: relative;
  color: var(--main-text);
}
.bxs-bell.bx-tada {
  color: var(--accent-color);
}

.head_profile {
  width: 32px;
  height: 32px;
  background-color: var(--secondary-bg-tint);
  transition: var(--transition-background);
  background-size: cover;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--actions-color);
  position: relative;
  pointer-events: initial !important;
  user-select: none;
}
.head_profile::before {
  content: '';
  border-radius: 50%;
  border: 1px solid rgb(0 0 0 / 5%);
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  top: 0;
  left: 0;
}
.head_search {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 36px;
  margin-left: .5rem;
}
.head_search_field {
  width: 220px;
  height: 36px;
  background-color: var(--secondary-bg-tint);
  color: var(--main-text);
  border: 2px solid transparent;
  border-radius: 50px;
  padding: .6rem 1rem;
  transition-duration: .2s;
  transition-property: border-color, background-color;
  z-index: 1;
}
.head_search_field:focus {
  border-color: var(--form-border-color-active);
}
.head_search_ic {
  display: none !important;
}
@media (max-width: 600px) {
  .head_search {
    justify-content: center;
    width: 36px;
    border-radius: var(--border-radius);
    transition: var(--transition-background);
    cursor: pointer;
  }
  .head_search:hover {
    background-color: var(--secondary-bg-tint-focus)
  }
  .head_search_form {
    display: none;
  }
  .head_search.open .head_search_form {
    display: flex;
    position: absolute;
    right: 0;
    width: calc(100% - 48px);
  }
  .head_search.open .head_search_field {
    width: 100%;
  }
  .head_search_ic {
    display: block !important;
  }
}
.head_auth {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: .5rem;
}
.head_auth .btn {
  margin: 0;
  height: 36px;
}
.showLg {
  display: inline-block;
}
.showSm {
  display: none;
}
@media (max-width: 768px) {
  .showLg {
    display: none;
  }
  .showSm {
    display: inline-block;
  }
}